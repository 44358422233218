const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
const callbackName = "gmapsCallback"

let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise

const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

export default function init() {
  if (initialized) {
    return initPromise
  }

  initialized = true
  window[callbackName] = () => resolveInitPromise(window.google)

  const script = document.createElement("script")
  script.async = true
  script.defer = true
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=${callbackName}`
  script.onerror = rejectInitPromise
  document.querySelector("head").appendChild(script)

  return initPromise
}

export function embed(address) {
  const url = `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=13&size=320x200&maptype=roadmap&key=${apiKey}`
  return encodeURI(url)
}
