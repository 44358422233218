<template>
  <v-app>
    <v-main>
      <v-container ref="content" class="pa-0" :fluid="isXSBreakpoint">
        <v-row dense>
          <v-col cols="12">
            <v-card elevation="0">
              <p class="text-center text-h6 text-uppercase">Pizza X Store Finder</p>
              <p class="text-center">
                Need help finding which store delivers to you?
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="pt-0" cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
            <v-card class="ml-2 mr-1" elevation="3">
              <v-card-text class="pt-0">
                <v-text-field
                  ref="searchInput"
                  v-model="address"
                  clearable
                  hint="ex. '123 N Main St Bloomington IN'"
                  placeholder="Type your address here"
                  @focus="visible.searchButton = true"
                  @keyup.enter="lookup(address)"
                />
                <v-fab-transition>
                  <v-btn
                    ref="searchButton"
                    absolute
                    bottom
                    @click="lookup(address)"
                    color="primary"
                    dark
                    fab
                    right
                    v-show="visible.searchButton"
                  >
                    <v-icon v-show="!status.searching">mdi-magnify</v-icon>
                    <v-progress-circular v-show="status.searching" color="white" indeterminate />
                  </v-btn>
                </v-fab-transition>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template v-if="locations && locations.length > 0">
          <v-row v-if="!restaurant" dense>
            <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
              <v-alert class="mb-0 ml-2 mr-1" prominent type="info">
                This address is outside our delivery area but you can still place a carryout order
                from one of our locations below.
              </v-alert>
            </v-col>
          </v-row>
          <template v-else>
            <v-row v-if="dialogs.extendedDelivery">
              <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
                <v-card class="ml-2 mr-1" elevation="3">
                  <v-card-text>
                    If your delivery store is closed, our Campus store has an extended delivery area
                    during lunch and late night. Feel free to call the Campus store at
                    <a href="tel:812-339-7737">812-339-7737</a> or use the buttons below to see if
                    you are in that area.
                    <v-row class="mt-5">
                      <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
                        <v-btn
                          block
                          class="px-0"
                          color="primary"
                          large
                          @click.stop="goto(urls.lunchDeliveryMap)"
                        >
                          Show me the lunch map
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
                        <v-btn
                          block
                          class="px-0"
                          color="primary"
                          large
                          @click.stop="goto(urls.lateDeliveryMap)"
                        >
                          Show me the late night map
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
                <location
                  :location="restaurant"
                  :selected="restaurant"
                  @map="showMap($event)"
                  @order="orderNow($event)"
                  @notopen="showExtendedDeliveryMessage()"
                />
              </v-col>
            </v-row>
          </template>
          <v-row dense>
            <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
              <v-alert class="mb-0 ml-2 mr-1" icon="mdi-arrow-down-thick" outlined prominent>
                The following locations are also available for carryout orders.
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-for="location in carryoutOnly" :key="location.id">
            <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
              <location
                :location="location"
                :selected="restaurant"
                @map="showMap($event)"
                @order="orderNow($event)"
              />
            </v-col>
          </v-row>
        </template>
        <v-row v-else-if="status.hasSearched" dense>
          <v-col cols="12" sm="8" offset-sm="2" lg="6" offset-lg="3">
            <v-alert class="mb-0" prominent type="error">
              Sorry, Pizza X doesn't serve this location.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="dialogs.map" eager fullscreen>
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>{{ mapHeader }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn icon dark @click="dialogs.map = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div ref="map" class="map" :style="`height: ${dialogHeight}px;`" />
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        top
        vertical
      >
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.visible = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Location from "@/components/LocationTile"

import gmapsInit from "@/plugins/maps"

export default {
  components: {
    Location,
  },
  data: () => ({
    address: "",
    dialogs: {
      extendedDelivery: false,
      map: false,
    },
    geocoder: null,
    google: null,
    lastResults: null,
    locations: [],
    map: null,
    mapCoords: {},
    mapHeader: "",
    restaurant: null,
    snackbar: {
      color: "",
      message: "",
      timeout: 5000,
      visible: false,
    },
    status: {
      hasSearched: false,
      searching: false,
    },
    urls: {
      lunchDeliveryMap: "https://www.pizzaxbloomington.com/lunch-delivery",
      lateDeliveryMap: "https://www.pizzaxbloomington.com/late-night",
    },
    visible: {
      searchButton: false,
    },
    windowDimensions: {
      child: {
        height: null,
      },
      parent: {
        height: 0,
        width: 0,
      },
    },
  }),
  async mounted() {
    try {
      const google = await gmapsInit()
      this.google = google
      this.geocoder = new google.maps.Geocoder()
    } catch (error) {
      this.showSnackbarError("Whoops! Something went wrong. Please try again later.")
      this.status.searching = false
    }

    window.addEventListener("message", event => {
      if (
        !event.data.parentHeight ||
        event.data.parentHeight === this.windowDimensions.parent.height
      ) {
        return
      }
      this.windowDimensions.parent.height = event.data.parentHeight
    })

    this.updateDimensions()
  },
  computed: {
    carryoutOnly() {
      if (!this.locations || this.locations.length < 1) {
        return []
      }
      if (!this.restaurant) {
        return this.locations
      }
      return this.locations.filter(l => l.id !== this.restaurant.id)
    },
    coordinates() {
      if (!this.lastResults || this.lastResults.length < 1) {
        return null
      }

      return this.lastResults[0].geometry.location
    },
    count() {
      let count = 0
      if (this.lastResults) {
        count = this.lastResults.length
      }
      return count
    },
    dialogHeight() {
      const height = this.isXSBreakpoint
        ? this.windowDimensions.parent.height * 0.7
        : this.windowDimensions.parent.height * 0.65
      return height.toFixed(0)
    },
    isXSBreakpoint() {
      return this.$vuetify.breakpoint.name === "xs"
    },
  },
  methods: {
    goto(externalUrl) {
      window.parent.postMessage({ url: externalUrl }, process.env.VUE_APP_PARENT_WINDOW_HOSTNAME)
    },
    lookup(address) {
      this.$refs.searchInput.blur()
      this.$refs.searchButton.$el.blur()
      this.restaurant = null
      this.locations = []
      this.dialogs.extendedDelivery = false
      if (address && !this.status.searching) {
        this.status.searching = true
        this.status.hasSearched = false
        try {
          this.geocoder.geocode({ address: address }, (results, status) => {
            if (status !== "OK" || !results[0]) {
              this.showSnackbarError(
                "Sorry, we couldn't find this address. Try including city, state, and zip code in addition to your street address."
              )
              this.status.searching = false
            }

            this.lastResults = results
            this.address = results[0].formatted_address
            const geometry = results[0].geometry
            const coords = {
              lat: geometry.location.lat(),
              lng: geometry.location.lng(),
            }

            this.$store
              .dispatch("getLocations", coords)
              .then(response => {
                if (response.data) {
                  this.restaurant = response.data.delivery
                  this.locations = response.data.pickup
                }
                this.updateDimensions()
                this.visible.searchButton = false
                this.status.searching = false
                this.status.hasSearched = true
              })
              .catch(() => {
                this.showSnackbarError("Whoops! Something went wrong. Please try again later.")
                this.status.searching = false
              })
          })
        } catch (error) {
          this.showSnackbarError("Whoops! Something went wrong. Please try again later.")
          this.status.searching = false
        }
      }
    },
    orderNow(baseUrl) {
      this.goto(`${baseUrl}/ordering/home`)
    },
    showExtendedDeliveryMessage() {
      this.dialogs.extendedDelivery = true
    },
    showMap(location) {
      if (location && location.address) {
        this.mapHeader = location.name
        window.parent.postMessage({ showingMap: true }, process.env.VUE_APP_PARENT_WINDOW_HOSTNAME)
        const id = location.id.replace(/[^a-zA-Z0-9]/g, "")

        if (!this.mapCoords[id]) {
          const address = `${location.address},${location.city},${location.state},USA`
          try {
            this.geocoder.geocode({ address: address }, (results, status) => {
              if (status !== "OK" || !results[0]) {
                this.showSnackbarError("Whoops! Something went wrong. Please try again later.")
              }

              this.mapCoords[id] = results[0]
              const geometry = this.mapCoords[id].geometry
              this.dialogs.map = true
              this.$nextTick(() => {
                const google = this.google
                const map = new google.maps.Map(this.$refs.map, {
                  zoom: 18,
                  center: geometry.location,
                })
                // this.map.setCenter(geometry.location)
                google.maps.event.addListenerOnce(map, "bounds_changed", () => {
                  map.setZoom(18)
                })
                map.fitBounds(geometry.viewport)
              })
            })
          } catch (error) {
            this.showSnackbarError("Whoops! Something went wrong. Please try again later.")
            return
          }
        } else {
          const geometry = this.mapCoords[id].geometry
          this.dialogs.map = true
          this.$nextTick(() => {
            const google = this.google
            const map = new google.maps.Map(this.$refs.map, {
              zoom: 18,
              center: geometry.location,
            })
            // this.map.setCenter(geometry.location)
            google.maps.event.addListenerOnce(map, "bounds_changed", () => {
              map.setZoom(18)
            })
            map.fitBounds(geometry.viewport)
          })
        }
      }
    },
    showSnackbarError(message) {
      this.snackbar = {
        color: "error",
        message: message,
        timeout: 10000,
        visible: true,
      }
    },
    updateDimensions() {
      this.$nextTick(() => {
        const contentHeight = this.$refs.content.clientHeight
        if (this.windowDimensions.child.height !== contentHeight) {
          this.windowDimensions.child.height = contentHeight
          window.parent.postMessage(
            { childHeight: contentHeight },
            process.env.VUE_APP_PARENT_WINDOW_HOSTNAME
          )
        }
      })
    },
  },
}
</script>

<style scoped>
.map {
  width: 100vw;
}
</style>
