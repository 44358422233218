<template>
  <v-sheet
    :color="isSelectedLocation ? 'primary' : 'default'"
    class="ml-2 mr-1 pa-1"
    :elevation="isSelectedLocation ? 10 : 0"
  >
    <v-card light>
      <v-row dense>
        <v-col cols="12" sm="10" offset-sm="1">
          <v-list dense>
            <v-list-item :three-line="isSelectedLocation">
              <v-list-item-content>
                <v-list-item-title class="headline mb-1 pt-1">
                  {{ location.name }}
                  <v-chip class="float-right" color="grey" label outlined small>
                    {{ location.distance.toFixed(1) }} mi
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>{{ location.address }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <a :href="`tel:${location.telephone}`">{{ location.telephone }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-alert
              v-if="isSelectedLocation"
              border="bottom"
              class="mx-3 my-2"
              :color="isOpen ? 'info' : 'secondary'"
              colored-border
              dense
              :icon="isOpen ? 'mdi-truck-fast' : 'mdi-truck-fast-outline'"
              prominent
              text
            >
              Delivery available{{ isOpen ? "!" : " (when open)" }}
            </v-alert>
            <v-alert
              v-if="location.deliveryOnly"
              class="mx-3 my-2"
              dense
              elevation="2"
              outlined
              type="info"
            >
              This store only offers delivery. Please see the stores listed below for carryout
              orders.
            </v-alert>
            <v-list-item dense three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h6 mb-2">
                  Current Hours
                  <v-chip
                    class="float-right"
                    :color="isOpen ? 'info' : 'secondary'"
                    dark
                    label
                    small
                  >
                    {{ isOpen ? "Open" : "Closed" }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle v-for="(day, idx) in hours" :key="idx">
                  <v-container class="py-0">
                    <v-row dense>
                      <v-col
                        cols="3"
                        offset="1"
                        class="my-1 py-0 mr-0"
                        :class="openDay === day.weekDay ? 'font-weight-black' : ''"
                      >
                        {{ day.weekDay }}:
                      </v-col>
                      <v-col
                        cols="8"
                        class="mx-0 my-1 py-0"
                        :class="openDay === day.weekDay ? 'font-weight-black' : ''"
                      >
                        {{ toTwelveHour(day.open) }} - {{ toTwelveHour(day.close) }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="10" offset="1">
          <v-btn block color="primary" large @click="order(location.baseUrl)">
            Order Now
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" offset="1">
          <v-btn block @click="showMap()">Show map</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import { DateTime, Interval } from "luxon"

export default {
  props: {
    location: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    map: null,
    weekDays: {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    },
  }),
  computed: {
    hours() {
      if (!this.location.storeHours || !this.location.storeHours.any) {
        return []
      }
      const today = DateTime.local().set({ hour: 0, minute: 0 })
      return this.location.storeHours.any
        .map(h => {
          try {
            const weekDay = this.weekDays[h.day]
            const openTokens = h.openTime.split(":")
            const openHour = parseInt(openTokens[0])
            const openMinutes = parseInt(openTokens[1])
            const closeTokens = h.closeTime.split(":")
            const closeHour = parseInt(closeTokens[0])
            const closeMinutes = parseInt(closeTokens[1])
            const closeDayOffset = closeHour < openHour ? 1 : 0
            const openTime =
              weekDay < today.weekday
                ? today
                    .minus({ days: today.weekday - weekDay })
                    .set({ hour: openHour, minute: openMinutes })
                : today
                    .plus({ days: weekDay - today.weekday })
                    .set({ hour: openHour, minute: openMinutes })
            const closeTime =
              weekDay < today.weekday
                ? today
                    .minus({ days: today.weekday - weekDay })
                    .plus({ days: closeDayOffset })
                    .set({ hour: closeHour, minute: closeMinutes })
                : today
                    .plus({ days: weekDay - today.weekday })
                    .plus({ days: closeDayOffset })
                    .set({ hour: closeHour, minute: closeMinutes })
            return {
              open: openTime,
              close: closeTime,
              isClosed: h.closed,
              weekDay: openTime.toFormat("ccc"),
            }
          } catch {
            return null
          }
        })
        .filter(h => h)
    },
    isOpen() {
      if (!this.hours || this.hours.length < 1) {
        return false
      }
      const now = DateTime.local()
      const idx = this.hours.findIndex(h => {
        const interval = Interval.fromDateTimes(h.open, h.close)
        return interval.contains(now)
      })
      return idx > -1
    },
    isSelectedLocation() {
      return this.selected && this.selected.id === this.location.id
    },
    openDay() {
      if (!this.hours || this.hours.length < 1) {
        return ""
      }
      const now = DateTime.local()
      const day = this.hours.find(h => {
        const interval = Interval.fromDateTimes(h.open, h.close)
        return interval.contains(now)
      })
      if (day) {
        return day.weekDay
      }
      return now.toFormat("ccc")
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isSelectedLocation && !this.isOpen) {
        this.$emit("notopen")
      }
    })
  },
  methods: {
    order(baseUrl) {
      this.$emit("order", baseUrl)
    },
    showMap() {
      this.$emit("map", this.location)
    },
    toTwelveHour(timestamp) {
      if (timestamp.hour === 0 && timestamp.minute === 0) {
        return "Midnight"
      } else if (timestamp.hour === 12 && timestamp.minute === 0) {
        return "Noon"
      } else {
        return timestamp.toFormat("t")
      }
    },
  },
}
</script>
