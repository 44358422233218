<template>
  <!-- App.vue currently doesn't render the router view -->
  <!-- So this view doesn't actually do anything other than make Vue Router happy -->
  <div class="home"></div>
</template>

<script>
export default {
  name: "Home",
}
</script>
