import firebase from "firebase/app"
import "firebase/functions"
import Vue from "vue"
import Vuex from "vuex"

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
}
firebase.initializeApp(firebaseConfig)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    getAllLocations() {
      const getAll = firebase.functions().httpsCallable("all")
      return getAll()
    },
    getLocations(state, coords) {
      const find = firebase.functions().httpsCallable("find")
      return find(coords)
    },
  },
  modules: {},
})
